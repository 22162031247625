import React from 'react';
import Button from "../../components/bootstrap/Button";
import classNames from "classnames";
import useDarkMode from "../../hooks/useDarkMode";
import { useEffect, useState, useRef, useMemo } from "react";
import Icon from "../../components/icon/Icon";
import { generateCaseStudy } from "../../common/data/service";
import DOMPurify from "dompurify";
import JoditEditor from 'jodit-react';
import Cookies from 'js-cookie';
import { useFormik } from 'formik';
import { updateProjectDetail } from '../../common/data/service';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
} from '../../components/bootstrap/Modal';
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import { validateExpiredToken } from '../../App/Constants';
import { useNavigate } from 'react-router-dom';
import Alert, { AlertHeading } from '../../components/bootstrap/Alert';

const CaseStory = (props: any) => {
    const { projectId, setIsLoading, aedAccess, projectDetailsValues, handleEditButtonClick, setCaseStudyEdit } = props;
    const { darkModeStatus } = useDarkMode();
    const navigate = useNavigate();
    const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
    const [editContent, setEditContent] = useState("")
    const [characterLength, setCharacterLength] = useState(0)
    const [alert, setAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>("");
    const [reGenerateConfirmation, setReGenerateConfirmation] = useState(false);


    const handleEditState = (editState: boolean) => {
        setIsEditOpen(editState);
        handleEditButtonClick(editState);
        setCaseStudyEdit(editState);
    }
    const handleClose = () => {
        handleEditState(false);
    };

    const formik = useFormik({
        onSubmit: (values) => {
            updateProjectDetail({
                projectId: values.projectId,
                aedCaseStudy: values.caseStudy
            }).catch((e) => {
                if (!validateExpiredToken(e.message)) {
                    navigate('/');
                    window.location.reload();
                }
            });
            setAlertMessage('Saved Successfully');
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
            }, 3000)
        },
        initialValues: {
            projectId: projectId,
            caseStudy: ""
        }
    })

		const editor = useRef(null);
		const [content, setContent] = useState('');

        const configuration = {
            enter: "br" as "br",
            readonly: false,
            uploader: {
              insertImageAsBase64URI: true
            },
            removeButtons: ['video', 'file', 'speechRecognize', 'classSpan', 'fullsize', 'preview', 'print', 'about'],
            addNewLine: false
          };

    useEffect(() => {
        if (Object.keys(projectDetailsValues).length > 0) {
            const caseStudyData = projectDetailsValues?.data?.data[0]?.aed_case_study_json || "";
            formik.setFieldValue('caseStudy', caseStudyData)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectDetailsValues])
    const decodeUnicode = (str: string): string => {
        return str.replace(/\\u[\dA-F]{4}/gi, (match) => {
            return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
        });
    };

    const handleGenerateClick = async () => {
        try {
            setIsLoading(true)
            const response = await generateCaseStudy({ projectId: projectId })
            formik.setFieldValue('caseStudy', response.data);
            setAlertMessage('Generated Successfully');
        } catch (err) {
            console.error(err);
            setAlertMessage('Generated failed');
        } finally {
            setIsLoading(false)
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
            }, 3000)
        }
    };
    const sanitizeData = (data: any) => {
        return DOMPurify.isSupported ? DOMPurify.sanitize(data, { ADD_ATTR: ['target'] }) : ''
    }
    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <div className="row g-3 align-items-center">
                        <div className="col d-flex align-items-center">
                            <div className="flex-shrink-0">
                                <div className="ratio ratio-1x1 aed-icon-box">
                                    <div className={classNames("rounded-2 d-flex align-items-center justify-content-center", darkModeStatus ? "bg-lo25-primary" : "bg-l10-primary")}>
                                        <label
                                            className="border-0 bg-transparent me-0"
                                            htmlFor="Link">
                                            <Icon icon="ManageSearch" size="2x" color='primary' />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-3 d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="fw-bold fs-6 mb-0">
                                        <h5 className="card-title">Case Study</h5>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card-actions'>
                        {aedAccess?.edit &&
                        <Button
                            icon='print'
                            color="info"
                            onClick={() => {
                                formik.values.caseStudy?.length > 0 ? setReGenerateConfirmation(true) : handleGenerateClick()
                            }}>
                            {formik.values.caseStudy?.length > 0 ? 'Regenerate' : 'Generate'}
                        </Button>}
                        {aedAccess?.edit &&
                        (isEditOpen ? (
                        <Button
                            isOutline={!darkModeStatus}
                            color='dark'
                            isLight={darkModeStatus}
                            className={classNames('text-nowrap', {
                                'border-light': !darkModeStatus,
                            })}
                            icon={'close'}
                            onClick={() => {
                                handleEditState(false);
                            }}
                        ></Button>
                        ) : 
                        (<Button
                            isOutline={!darkModeStatus}
                            color='dark'
                            isLight={darkModeStatus}
                            className={classNames('text-nowrap', {
                                'border-light': !darkModeStatus,
                            })}
                            icon={'Edit'}
                            isDisable={!(formik.values.caseStudy?.length > 0)}
                            onClick={() => {
                                handleEditState(true);
                                setEditContent(formik.values.caseStudy)
                            }}
                        ></Button>))}
                    </div>
                </div>

                <div className="card-body">
                    {isEditOpen ? (
                        <>
                        <JoditEditor
                                ref={editor}
                                value={editContent}
                                config={configuration}
                                onBlur={newContent => setEditContent(newContent)}
                        />
                        <Button
                        className='cs-save'
                        color='info'
                        onClick={async () => {
                            await setIsLoading(true);
                            await formik.setFieldValue('caseStudy', editContent);
                            await formik.submitForm();
                            handleEditState(false);
                            setIsLoading(false);
                        }}>Save
                        </Button>
                        </>
                        ):(<div
                        className="aed-card-description"
                        dangerouslySetInnerHTML={{ __html: sanitizeData(formik.values.caseStudy) }}
                    />
                    )}

                </div>
            </div>

            <Modal
                setIsOpen={setReGenerateConfirmation}
                isOpen={reGenerateConfirmation}
                isStaticBackdrop={true}
                isCentered={true}
                titleId='Confirm Regenerate'>
                <ModalHeader setIsOpen={setReGenerateConfirmation}>
                    <ModalTitle id='new-todo-modal'>Warning:</ModalTitle>
                </ModalHeader>
                <ModalBody>Regenerating will delete your current Case Study version. Would you like to continue?</ModalBody>
                <ModalFooter>
                    <Button
                        color='danger'
                        onClick={() => {
                            handleGenerateClick()
                            setReGenerateConfirmation(false);
                            handleEditState(false);
                        }}>
                        Yes
                    </Button>
                    &nbsp;
                    <Button color='success' onClick={() => setReGenerateConfirmation(false)}>
                        No
                    </Button>
                </ModalFooter>
            </Modal>
            {
                alert &&
                <Alert
                    icon=''
                    isLight
                    color='primary'
                    borderWidth={0}
                    className='cgp-container-alert'
                >
                    <AlertHeading tag='h2' className={classNames('text-sm cgp-container-alertHeading')}>
                        {alertMessage}
                    </AlertHeading>
                </Alert>
            }
        </div>
    )
}

export default CaseStory
